import React, { Component } from "react";
import Axios from "axios";
import { Home } from "../../Containers";
import { hideModal } from "../../Theme/js/main";

export default class index extends Component {
  onSubmit = async (e, data) => {
    e.preventDefault();
    if (
      data.username.trim() &&
      data.role.trim() &&
      data.postcode.trim() &&
      data.email.trim()
    )
      await Axios.post("/api/signup", {
        ...data,
      })
        .then((res) => {
          hideModal();
          console.log(res);
          alert(
            "Thanks for registering your interest. We will be contact you very soon!"
          );
        })
        .catch((err) => {
          hideModal();
          console.log(err);
          alert("This email address has already been registered. Thank you!");
          //alert(err.message);
        });
    else {
      console.log("Fill in all the fields");
      alert("Please fill in all the fields");
    }
  };

  render() {
    return (
      <Home.Banner>
        <Home.Body />
        <Home.About />
        <Home.Form onSubmit={this.onSubmit} />
        <Home.Footer />
        <Home.IntroModal onSubmit={this.onSubmit} />
      </Home.Banner>
    );
  }
}
