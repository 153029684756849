import React from "react";
const Banner = React.lazy(() => import("./banner"));

const Body = React.lazy(() => import("./body"));
const Form = React.lazy(() => import("./form"));
const About = React.lazy(() => import("./about"));
const Footer = React.lazy(() => import("./footer"));
const IntroModal = React.lazy(() => import("./modal"));

export { Banner, Body, Form, About, Footer, IntroModal };
