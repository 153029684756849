export function showFunction() {
  document.getElementById("dropDown").classList.toggle("show");
}

window.onload = function () {
  const menuBtn = document.querySelector(".ham");
  let menuOpen = false;
  menuBtn.addEventListener("click", () => {
    if (!menuOpen) {
      menuBtn.classList.add("open");
      menuOpen = true;
    } else {
      menuBtn.classList.remove("open");
      menuOpen = false;
    }
  });
};

export const hideModal = () => {
  document.getElementById("registerModal").classList.add("hide");
};

export const scrollTo = (id) => {
  document.getElementById(id).scrollIntoView({
    behavior: "smooth",
    block: "center",
    inline: "center",
  });
};
