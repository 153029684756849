import React from "react";
import { HomePage } from "../Components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HttpsRedirect from "react-https-redirect";

export default function index() {
  return (
    <HttpsRedirect>
      <Router>
        <Switch>
          <React.Suspense
            fallback={<div className="loading">Loading&#8230;</div>}
          >
            <Route path="/" component={HomePage} />
          </React.Suspense>
        </Switch>
      </Router>
    </HttpsRedirect>
  );
}
